import AppointmentAttributes from '@/types/AppointmentAttributes';

export interface DashboardState {
    patientsCount: number;
    patientsDeltaThisWeek: number;
    doctorsCount: number;
    appointmentsByDays: Record<string, number>;
    appointmentsThisWeek: number;
    appointments: AppointmentAttributes[];
}

const state : DashboardState = {
    patientsCount: 0,
    patientsDeltaThisWeek: 0,
    doctorsCount: 0,
    appointmentsByDays: {},
    appointmentsThisWeek: 0,
    appointments: []
};

export default state;
