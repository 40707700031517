import { MutationTree } from 'vuex';
import { RegisterState } from '@/store/register/state';

const mutations : MutationTree<RegisterState> = {
    form(state, data: object) {
        Object.assign(state.form, data);
    },

    resetForm(state) {
        state.form = {
            phone: '+380',
            password: '',
            passwordConfirm: '',
            fullName: '',
            speciality: '',
            email: '',

            errors: {}
        };
    }
};

export default mutations;
