<template>
  <layout-blank>
    <slot></slot>
  </layout-blank>
</template>

<script>
import LayoutBlank from '@/@core/layouts/variants/blank/LayoutBlank.vue';

export default {
    components: {
        LayoutBlank
    }
};
</script>

<style>
</style>
