import { MutationTree } from 'vuex';
import { AppointmentsState } from '@/store/appointments/state';
import { PublicSelfRegistrationState } from '@/store/public-self-registration/state';

const mutations : MutationTree<PublicSelfRegistrationState> = {
    update(state, data: Partial<AppointmentsState>) {
        Object.assign(state, data);
    },

    form(state, data: object) {
        Object.assign(state.form, data);
    },

    resetForm(state) {
        state.form = {
            appointmentType: 'regular',
            patientPhone: '+380',
            patientFullName: '',
            patientBirthday: '',
            doctorId: '',
            startsAt: '',
            patientComplaints: '',

            errors: {}
        };
    }
};

export default mutations;
