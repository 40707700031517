import { MutationTree } from 'vuex';
import { ProfileState } from '@/store/profile/state';
import { UserAttributes } from '@/types/UserAttributes';

const mutations : MutationTree<ProfileState> = {
    update(state, data: Partial<ProfileState>) {
        Object.assign(state, data);
    },

    form(state, data: object) {
        Object.assign(state.form, data);
    },

    loadUser(state, user: UserAttributes) {
        state.form = {
            phone: user.phone,
            password: '',
            passwordConfirm: '',
            fullName: user.fullName,
            speciality: user.speciality,
            email: user.email,

            errors: {}
        };
    }
};

export default mutations;
