export interface ProfileState {
    isLoading: boolean;

    form: {
        phone: string,
        password: string,
        passwordConfirm: string,
        fullName: string,
        speciality: string,
        email?: string | null,

        errors: Record<string, string[]>,
    },

}

const state : ProfileState = {
    isLoading: false,

    form: {
        phone: '+380',
        password: '',
        passwordConfirm: '',
        fullName: '',
        speciality: '',
        email: '',

        errors: {}
    }
};

export default state;
