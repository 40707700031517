import { AppState } from '@/store/app/state';
import { MutationTree } from 'vuex';
import { SelfRegistrationsState } from '@/store/self-registrations/state';

const mutations : MutationTree<SelfRegistrationsState> = {
    update(state, data: Partial<AppState>) {
        Object.assign(state, data);
    }
};

export default mutations;
