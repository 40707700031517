import { ActionTree } from 'vuex';
import { api, prepareRequestData } from '@/helpers/api';
import AppointmentAttributes from '@/types/AppointmentAttributes';
import { ResourceResponse } from '@/types/responses/ResourceResponse';
import { AppointmentViewState } from '@/store/appointment-view/state';
import { PatientAttributes } from '@/types/PatientAttributes';
import { AttachmentAttributes } from '@/types/AttachmentAttributes';
import { ValidationResponse } from '@/types/responses/ValidationResponse';
import { ServiceAttributes } from '@/types/ServiceAttributes';
import { activeClinic } from '@/helpers/auth';

const actions : ActionTree<AppointmentViewState, any> = {
    async fetchServices(context) {
        context.commit('update', { isLoading: true });

        const result = await api<ResourceResponse<ServiceAttributes>>({
            endpoint: `clinics/${activeClinic().id}/services/all`,
            method: 'get'
        });

        context.commit('update', {
            isLoading: false,
            services: result.data.data
        });
    },

    async fetchAppointment(context, id: string) {
        context.commit('update', { isLoading: true });

        const result = await api<ResourceResponse<AppointmentAttributes>>({
            endpoint: `appointments/${id}`,
            method: 'get'
        });

        context.commit('update', {
            isLoading: false,
            appointment: result.data.data,
            originAppointment: { ...result.data.data }
        });

        context.dispatch('fetchPatient');
    },

    async patchAppointment(context, data: Partial<AppointmentAttributes>) {
        context.commit('update', { isLoading: true });

        const response = await api({
            endpoint: `appointments/${context.state.appointment?.id}`,
            method: 'patch',
            data: {
                ...data,
                services: context.state.appointment?.services?.map((s: ServiceAttributes) => s.id),
                clinicId: context.state.appointment?.clinicId
            }
        });

        context.commit('update', { isLoading: false });

        return response.status === 200;
    },

    async fetchPatient(context) {
        if (!context.state.appointment) {
            return;
        }

        const response = await api<ResourceResponse<PatientAttributes>>({
            endpoint: `patients/${context.state.appointment.patientId}`,
            method: 'get'
        });

        if (response.status !== 200) {
            return;
        }

        context.commit('update', { patient: response.data.data });
    },

    async addAttachment(context) : Promise<boolean> {
        if (!context.state.appointment) {
            return false;
        }

        const formData = new FormData();

        Object.entries(prepareRequestData(context.state.attachmentForm)).map(([k, v]) => {
            formData.set(k, v as unknown as any);
        });

        formData.set('appointment_id', context.state.appointment.id);

        const response = await api<ValidationResponse & ResourceResponse<AttachmentAttributes>>({
            endpoint: 'attachments',
            method: 'post',
            data: formData
        });

        if (response.status !== 201) {
            context.commit('attachmentForm', { errors: response.data.errors });
            return false;
        }

        await context.dispatch('fetchAppointment', context.state.appointment.id);
        return true;
    },

    async addResearch(context) : Promise<boolean> {
        if (!context.state.appointment) {
            return false;
        }

        const response = await api<ValidationResponse & ResourceResponse<AttachmentAttributes>>({
            endpoint: 'researches',
            method: 'post',
            data: {
                ...prepareRequestData(context.state.researchForm),
                appointment_id: context.state.appointment.id
            }
        });

        if (response.status !== 201) {
            context.commit('researchForm', { errors: response.data.errors });
            return false;
        }

        await context.dispatch('fetchAppointment', context.state.appointment.id);
        return true;
    }
};

export default actions;
