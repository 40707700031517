import { ActionTree } from 'vuex';
import { api, prepareRequestData } from '@/helpers/api';
import { ValidationResponse } from '@/types/responses/ValidationResponse';
import { ProfileState } from '@/store/profile/state';
import { UserAttributes } from '@/types/UserAttributes';
import { ResourceResponse } from '@/types/responses/ResourceResponse';

export const actions : ActionTree<ProfileState, any> = {
    async saveProfile(context) : Promise<false | UserAttributes> {
        context.commit('update', { isLoading: true });

        const result = await api<ValidationResponse & ResourceResponse<UserAttributes>>({
            endpoint: 'profile',
            method: 'post',
            data: prepareRequestData(context.state.form)
        });

        context.commit('update', { isLoading: false });

        if (result.status !== 200) {
            context.commit('form', { errors: result.data.errors });
            return false;
        }

        return result.data.data;
    }
};
