import state, { SelfRegistrationsState } from './state';
import mutations from './mutations';
import { Module } from 'vuex';
import { actions } from './actions';

const store : Module<SelfRegistrationsState, any> = {
    namespaced: true,
    state: state,
    getters: {},
    mutations: mutations,
    actions: actions
};

export default store;
