import state, { LoginState } from './state';
import mutations from './mutations';
import { Module } from 'vuex';
import { actions } from '@/store/login/actions';

const store : Module<LoginState, any> = {
    namespaced: true,
    state: state,
    getters: {},
    mutations: mutations,
    actions: actions
};

export default store;
