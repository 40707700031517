import { PaginatedDataResponse } from '@/types/responses/PaginatedDataResponse';
import SelfRegistrationAttributes from '@/types/SelfRegistrationAttributes';

export interface SelfRegistrationsState {
    isLoading: boolean,

    selfRegistrations: SelfRegistrationAttributes[],

    tableOptions: object,

    paginationMeta: PaginatedDataResponse['meta'] | null,
}

const state : SelfRegistrationsState = {
    isLoading: true,

    selfRegistrations: [],

    tableOptions: {},

    paginationMeta: null
};

export default state;
