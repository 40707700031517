import {
    mdiAccountClock,
    mdiAccountMultiple,
    mdiAccountMultiplePlus,
    mdiCalendarAccount,
    mdiCog,
    mdiListStatus, mdiMonitorDashboard,
    mdiNeedle,
    mdiStethoscope
} from '@mdi/js';

export default [
    {
        title: 'Головна',
        icon: mdiMonitorDashboard,
        to: 'dashboard',
        action: 'dashboard'
    },

    {
        title: 'Прийом паціентів',
        icon: mdiStethoscope,
        action: 'appointments',
        children: [
            {
                title: 'Записати пацієнта',
                icon: mdiAccountMultiplePlus,
                to: 'appointments-new'
            },

            // {
            //     title: 'Календар прийомів',
            //     icon: mdiCalendarAccount,
            //     to: 'appointments-calendar'
            // },

            {
                title: 'Список прийомів',
                icon: mdiListStatus,
                to: 'appointments'
            },

            {
                title: 'Самозапис пацієнтів',
                icon: mdiAccountClock,
                to: 'self-registrations'
            }
        ]
    },

    {
        title: 'Паціенти',
        icon: mdiAccountMultiple,
        to: 'patients',
        action: 'patients'
    },

    {
        title: 'Послуги',
        icon: mdiNeedle,
        to: 'services',
        action: 'services'
    },

    {
        title: 'Адміністрування',
        icon: mdiCog,
        to: 'settings',
        action: 'settings'
    }
];
