import { AppState } from '@/store/app/state';
import { MutationTree } from 'vuex';

const mutations : MutationTree<AppState> = {
    TOGGLE_CONTENT_OVERLAY(state, value?: boolean) {
        state.shallContentShowOverlay = value !== undefined ? value : !state.shallContentShowOverlay;
    },

    update(state, data: Partial<AppState>) {
        Object.assign(state, data);
    },
};

export default mutations;
