export interface RegisterState {
    form: {
        phone: string,
        password: string,
        passwordConfirm: string,
        fullName: string,
        speciality: string,
        email?: string,

        errors: Record<string, string[]>,
    },

}

const state : RegisterState = {
    form: {
        phone: '+380',
        password: '',
        passwordConfirm: '',
        fullName: '',
        speciality: '',
        email: '',

        errors: {}
    }
};

export default state;
