import { VueMaskDirective } from 'v-mask';
import { i18n } from '@/plugins/i18n';
import '@/plugins/vue-composition-api';
import '@/styles/styles.scss';
import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';
import VueGtag from 'vue-gtag';

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    i18n,
    vuetify,
    render: h => h(App)
}).$mount('#app');

Vue.directive('mask', VueMaskDirective);

Vue.use(VueGtag, {
    config: { id: 'G-SSFEQ473FQ' }
});
