import { MutationTree } from 'vuex';
import { AppointmentsState } from '@/store/appointments/state';
import { AppointmentViewState } from '@/store/appointment-view/state';

const mutations : MutationTree<AppointmentViewState> = {
    update(state, data: Partial<AppointmentsState>) {
        Object.assign(state, data);
    },

    attachmentForm(state, data: object) {
        Object.assign(state.attachmentForm, data);
    },

    researchForm(state, data: object) {
        Object.assign(state.researchForm, data);
    },

    resetForms(state) {
        state.attachmentForm = {
            description: '',
            attachmentAt: '',
            file: null,
            errors: {}
        };

        state.researchForm = {
            researchType: '',
            description: '',
            errors: {}
        };
    }
};

export default mutations;
