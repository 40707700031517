import { ActionTree } from 'vuex';
import { api, prepareRequestParams } from '@/helpers/api';
import { PaginatedDataResponse } from '@/types/responses/PaginatedDataResponse';
import { activeClinic } from '@/helpers/auth';
import { SelfRegistrationsState } from '@/store/self-registrations/state';
import SelfRegistrationAttributes from '@/types/SelfRegistrationAttributes';

export const actions : ActionTree<SelfRegistrationsState, any> = {
    async fetchList(context) {
        context.commit('update', { isLoading: true });

        const result = await api<PaginatedDataResponse<SelfRegistrationAttributes[]>>({
            endpoint: `clinics/${activeClinic().id}/self-registrations`,
            method: 'get',
            params: prepareRequestParams(context.state.tableOptions)
        });

        context.commit('update', {
            isLoading: false,
            selfRegistrations: result.data.data,
            paginationMeta: result.data.meta
        });
    },

    async deleteItem(context, item: SelfRegistrationAttributes) : Promise<boolean> {
        context.commit('update', { isLoading: true });

        const result = await api({
            endpoint: `self-registrations/${item.id}`,
            method: 'delete'
        });

        context.commit('update', { isLoading: false });

        if (result.status !== 204) {
            return false;
        }

        context.dispatch('fetchList');
        return true;
    }
};
