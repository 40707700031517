import { DashboardState } from '@/store/dashboard/state';
import { ActionTree } from 'vuex';
import { api } from '@/helpers/api';
import { DashboardResponse } from '@/types/responses/DashboardResponse';
import { activeClinic } from '@/helpers/auth';

const actions : ActionTree<DashboardState, any> = {
    async fetchDashboard(context) {
        const result = await api<DashboardResponse>({
            endpoint: `clinics/${activeClinic().id}/dashboard`,
            method: 'get'
        });

        context.commit('update', result.data);
    }
};

export default actions;
