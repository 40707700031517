import { ActionTree } from 'vuex';
import { api } from '@/helpers/api';
import { ValidationResponse } from '@/types/responses/ValidationResponse';
import { ClinicsCreateStore } from '@/store/clinics-create/state';
import { ResourceResponse } from '@/types/responses/ResourceResponse';
import { ClinicAttributes } from '@/types/ClinicAttributes';

export const actions : ActionTree<ClinicsCreateStore, any> = {
    async create(context) : Promise<false | ClinicAttributes> {
        const result = await api<ValidationResponse & ResourceResponse<ClinicAttributes>>({
            endpoint: 'clinics',
            method: 'post',
            data: {
                ...context.state.form,
                appointmentConfig: []
            }
        });

        if (result.status !== 201) {
            context.commit('form', { errors: result.data.errors });
            return false;
        }

        return result.data.data;
    }
};
