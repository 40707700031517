import AppointmentAttributes from '@/types/AppointmentAttributes';

export interface PublicAppointmentState {
    isLoading: boolean;

    accessCode: string;
    appointment: AppointmentAttributes | null;

    researchForm: {
        id: string,
        file: File | null;
        errors: Record<string, string[]>,
    }
}

const state : PublicAppointmentState = {
    isLoading: false,

    accessCode: '',
    appointment: null,

    researchForm: {
        id: '',
        file: null,
        errors: {}
    }
};

export default state;
