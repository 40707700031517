<template>
    <div>
        <slot></slot>
    </div>
</template>

<script>
export default {};
</script>

<style>
@import "@/styles/print.scss";
</style>
