import { ClinicAttributes } from '@/types/ClinicAttributes';
import { FinOperationAttributes } from '@/types/FinOperationAttributes';

export interface SettingsAppointmentByDay {
    dayWeek: number;
    isWorking: boolean;
    fromHours: string;
    toHours: string;
}

export interface SettingsState {
    isLoading: boolean;

    clinic: ClinicAttributes | null;

    finOperations: FinOperationAttributes[];

    inviteForm: {
        roles: number[],
        phone: string;

        errors: Record<string, string[]>
    },

    form: {
        name: string;
        location: string;
        clinicPhone: string;
        smsBitmask: number;
        appointmentLengthMinutes: number | '';
        appointmentByDays: SettingsAppointmentByDay[];
        allowSelfRegistration: boolean;
        licenseId: string;

        errors: Record<string, string[]>
    }
}

const state : SettingsState = {
    isLoading: true,

    clinic: null,

    finOperations: [],

    inviteForm: {
        roles: [],
        phone: '+380',

        errors: {}
    },

    form: {
        name: '',
        clinicPhone: '',
        location: '',
        smsBitmask: 0,
        appointmentLengthMinutes: '',
        appointmentByDays: [],
        allowSelfRegistration: false,
        licenseId: '',

        errors: {}
    }
};

export default state;
