import { ActionTree } from 'vuex';
import { api } from '@/helpers/api';
import AppointmentAttributes from '@/types/AppointmentAttributes';
import { ResourceResponse } from '@/types/responses/ResourceResponse';
import { AttachmentAttributes } from '@/types/AttachmentAttributes';
import { ValidationResponse } from '@/types/responses/ValidationResponse';
import { PublicAppointmentState } from '@/store/public-appointment/state';

const actions : ActionTree<PublicAppointmentState, any> = {
    async fetchAppointment(context) {
        context.commit('update', { isLoading: true });

        const result = await api<ResourceResponse<AppointmentAttributes>>({
            endpoint: 'public/appointments/access-code',
            method: 'get',
            headers: {
                AppointmentAccessCode: context.state.accessCode
            }
        });

        context.commit('update', {
            isLoading: false
        });

        if (result.status !== 200) {
            return false;
        }

        context.commit('update', {
            appointment: result.data.data
        });

        return true;
    },

    async attachResearchResult(context, { researchId, file }) : Promise<boolean> {
        if (!context.state.appointment) {
            return false;
        }

        context.commit('update', { isLoading: true });

        const formData = new FormData();
        formData.set('file', file);

        const response = await api<ValidationResponse & ResourceResponse<AttachmentAttributes>>({
            endpoint: `public/researches/${researchId}/access-code`,
            method: 'post',
            data: formData,
            headers: {
                AppointmentAccessCode: context.state.accessCode
            }
        });

        context.commit('update', { isLoading: true });

        if (response.status !== 200) {
            return false;
        }

        await context.dispatch('fetchAppointment');
        return true;
    }
};

export default actions;
