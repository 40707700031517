import Vue from 'vue';
import VueRouter from 'vue-router';
import { isAuthenticated, user } from '@/helpers/auth';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        redirect: '/dashboard'
    },
    {
        path: '/error-404',
        name: 'error-404',
        component: () => import('@/views/Error404.vue'),
        meta: {
            layout: 'blank',
            resource: 'Public'
        }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/Login.vue'),
        meta: {
            layout: 'blank',
            resource: 'Public',
            redirectIfLoggedIn: true
        }
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('@/views/Register.vue'),
        meta: {
            layout: 'blank',
            resource: 'Public',
            redirectIfLoggedIn: true
        }
    },
    {
        path: '/clinics/create',
        name: 'clinics-create',
        component: () => import('@/views/ClinicsCreate.vue'),
        meta: {
            layout: 'blank',
            resource: 'Public',
            redirectIfLoggedIn: true
        }
    },
    {
        path: '/forgot-password',
        name: 'auth-forgot-password',
        component: () => import('@/views/ForgotPassword.vue'),
        meta: {
            layout: 'blank',
            resource: 'Public',
            redirectIfLoggedIn: true
        }
    },

    {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('@/views/dashboard/Dashboard.vue'),
        meta: {
            layout: 'content'
        }
    },

    {
        path: '/appointments/list/:startDate?',
        name: 'appointments',
        component: () => import('@/views/appointments/list/Appointments.vue'),
        meta: {
            layout: 'content'
        }
    },

    {
        path: '/appointments/new',
        name: 'appointments-new',
        component: () => import('@/views/appointments/new/New.vue'),
        meta: {
            layout: 'content'
        }
    },

    {
        path: '/appointments/calendar',
        name: 'appointments-calendar',
        component: () => import('@/views/appointments/calendar/Calendar.vue'),
        meta: {
            layout: 'content'
        }
    },

    {

        path: '/appointments/:id',
        name: 'appointments-view',
        component: () => import('@/views/appointments/view/View.vue'),
        meta: {
            layout: 'content'
        }
    },

    {

        path: '/appointments/:id/print',
        name: 'appointments-print',
        component: () => import('@/views/appointments/view/Print.vue'),
        meta: {
            layout: 'print'
        }
    },

    {
        path: '/patients',
        name: 'patients',
        component: () => import('@/views/patients/Patients.vue'),
        meta: {
            layout: 'content'
        }
    },

    {
        path: '/patients/:id',
        name: 'patients-view',
        component: () => import('@/views/patients/View.vue'),
        meta: {
            layout: 'content'
        }
    },

    {
        path: '/services',
        name: 'services',
        component: () => import('@/views/services/Services.vue'),
        meta: {
            layout: 'content'
        }
    },

    {
        path: '/settings',
        name: 'settings',
        component: () => import('@/views/settings/Settings.vue'),
        meta: {
            layout: 'content'
        }
    },

    {
        path: '/profile',
        name: 'profile',
        component: () => import('@/views/Profile.vue'),
        meta: {
            layout: 'content'
        }
    },

    {
        path: '/self-registrations',
        name: 'self-registrations',
        component: () => import('@/views/self-registrations/SelfRegistrations.vue'),
        meta: {
            layout: 'content'
        }
    },

    {
        path: '/invites',
        name: 'invites',
        component: () => import('@/views/Invites.vue'),
        meta: {
            layout: 'blank'
        }
    },

    {
        path: '/my-appointment',
        name: 'public-appointment',
        component: () => import('@/views/public/Appointment.vue'),
        meta: {
            layout: 'blank'
        }
    },

    {
        path: '/self/:clinicId',
        name: 'public-self-registration',
        component: () => import('@/views/public/SelfRegistration.vue'),
        meta: {
            layout: 'blank'
        }
    },

    {
        path: '*',
        redirect: 'error-404'
    }
];

const router : VueRouter = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 };
    }
});

// ? Router Before Each hook: https://router.vuejs.org/guide/advanced/navigation-guards.html
router.beforeEach((to, _, next) => {
    const loginRouteNames = [
        'login',
        'register'
    ];

    const publicRoutes = [
        'public-appointment',
        'public-self-registration'
    ];

    if (isAuthenticated()) {
        const invites = user().invites;

        if (invites && invites.length > 0) {
            if (to.name != 'invites') {
                return next({name: 'invites'});
            }

            return next();
        }

        if (loginRouteNames.includes(to.name as string)) {
            return next({ name: 'dashboard' });
        }

        if (to.name != 'clinics-create' && !user().clinics?.length) {
            return next({ name: 'clinics-create' });
        }

        return next();
    }

    if (!loginRouteNames.includes(to.name as string) && !publicRoutes.includes(to.name as string)) {
        return next({ name: 'login' });
    }

    return next();
});

export default router;
