import preset from '@/@core/preset/preset';
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import { ru, uk } from 'vuetify/lib/locale';

Vue.use(Vuetify);

export default new Vuetify({
    preset,
    icons: {
        iconfont: 'mdiSvg'
    },
    lang: {
        locales: { uk, ru },
        current: 'uk'
    },
    theme: {
        options: {
            customProperties: true,
            variations: false
        }
    }
});
