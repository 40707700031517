import { MutationTree } from 'vuex';
import { DashboardState } from '@/store/dashboard/state';

const mutations : MutationTree<DashboardState> = {
    update(state, data: Partial<DashboardState>) {
        Object.assign(state, data);
    }
};

export default mutations;
