import { MutationTree } from 'vuex';
import { SettingsAppointmentByDay, SettingsState } from '@/store/settings/state';
import { ClinicAttributes } from '@/types/ClinicAttributes';

const mutations : MutationTree<SettingsState> = {
    update(state, data: Partial<SettingsState>) {
        Object.assign(state, data);
    },

    inviteForm(state, data: object) {
        Object.assign(state.inviteForm, data);
    },

    resetInviteForm(state) {
        state.inviteForm = {
            roles: [],
            phone: '+380',
            errors: {}
        };
    },

    form(state, data: object) {
        Object.assign(state.form, data);
    },

    loadClinicAsForm(state, clinic: ClinicAttributes) {
        const makeByDays = () => {
            const result : SettingsAppointmentByDay[] = [];

            for (let i = 0; i < 7; i++) {
                result.push({
                    dayWeek: i,
                    isWorking: false,
                    fromHours: '',
                    toHours: ''
                });
            }

            return result;
        };

        state.form = {
            name: clinic.name,
            location: clinic.location,
            clinicPhone: clinic.phone,
            smsBitmask: clinic.smsBitmask,
            appointmentLengthMinutes: clinic.appointmentConfig?.lengthMinutes ?? '',
            appointmentByDays: clinic.appointmentConfig?.byDays ?? makeByDays(),
            allowSelfRegistration: clinic.allowSelfRegistration ?? false,
            licenseId: clinic.licenseId ?? '',

            errors: {}
        };
    }
};

export default mutations;
