import { ClinicAttributes } from '@/types/ClinicAttributes';
import { UserAttributes } from '@/types/UserAttributes';

export interface PublicSelfRegistrationState {
    isLoading: boolean;

    clinic: ClinicAttributes | null;

    doctors: UserAttributes[];

    form: {
        appointmentType: 'regular' | 'online';
        patientPhone: string;
        patientFullName: string;
        patientBirthday: string | Date;
        doctorId: string;
        startsAt: string | Date;
        patientComplaints: string;

        errors: Record<string, string[]>,
    }
}

const state : PublicSelfRegistrationState = {
    isLoading: false,

    clinic: null,

    doctors: [],

    form: {
        appointmentType: 'regular',
        patientPhone: '+380',
        patientFullName: '',
        patientBirthday: '',
        doctorId: '',
        startsAt: '',
        patientComplaints: '',

        errors: {}
    }
};

export default state;
