import { UserAttributes } from '@/types/UserAttributes';
import store from '@/store';
import { ClinicAttributes } from '@/types/ClinicAttributes';
import { api } from '@/helpers/api';
import { AuthResponse } from '@/types/responses/AuthResponse';
import Vue from 'vue';
import { UserRoles } from '@/types/enums/UserRoles';
import { ResourceResponse } from '@/types/responses/ResourceResponse';

let refreshTokenInterval: ReturnType<typeof setInterval> | null = null;

export function isAuthenticated() : boolean {
    return !!store.state.app.userAuthToken;
}

export function loadUser() : void {
    if (!localStorage.getItem('userToken') || !localStorage.getItem('user')) {
        return;
    }

    try {
        const userAuthToken = localStorage.getItem('userToken') as string;
        const user = JSON.parse(localStorage.getItem('user') as string) as unknown as UserAttributes;
        const activeClinic = JSON.parse(localStorage.getItem('activeClinic') as string) as unknown as ClinicAttributes;

        store.commit('app/update', {
            user,
            userAuthToken,
            activeClinic
        });

        if (refreshTokenInterval) {
            clearInterval(refreshTokenInterval);
        }
        refreshTokenInterval = setInterval(async () => refreshToken(), 5 * 60 * 1000);
    } catch (error) {
        console.error('Unable to load user');
    }
}

export function authenticate(user: UserAttributes, token: string) : void {
    store.commit('app/update', {
        user,
        userAuthToken: token
    });

    localStorage.setItem('userToken', token);
    localStorage.setItem('user', JSON.stringify(user));

    if (user.clinics && user.clinics.length > 0) {
        let activeClinic = null;

        if (localStorage.getItem('activeClinic')) {
            try {
                activeClinic = JSON.parse(localStorage.getItem('activeClinic') as string) as ClinicAttributes;
            } catch (error) {
                activeClinic = user.clinics[0];
            }
        } else {
            activeClinic = user.clinics[0];
        }

        localStorage.setItem('activeClinic', JSON.stringify(activeClinic));
        store.commit('app/update', {
            activeClinic: activeClinic
        });
    }

    if (refreshTokenInterval) {
        clearInterval(refreshTokenInterval);
    }
    setInterval(async () => refreshToken(), 5 * 60 * 1000);
}

export async function refreshToken() {
    const result = await api<AuthResponse>({
        endpoint: 'refresh',
        method: 'post'
    });

    if (result.status !== 200) {
        return;
    }

    const token = result.data.access_token;

    store.commit('app/update', {
        userAuthToken: token
    });

    localStorage.setItem('userToken', token);
}

export function logout() : void {
    localStorage.setItem('userToken', '');
    localStorage.setItem('user', '');
}

export function user() : UserAttributes {
    if (!store.state.app.user) {
        throw new Error('User not logged in');
    }

    return store.state.app.user;
}

export async function refreshUser() {
    const response = await api<ResourceResponse<UserAttributes>>({
        endpoint: 'user-profile'
    });

    if (response.status !== 200) {
        throw new Error('Can\'t update user info');
    }

    const user = response.data.data;

    store.commit('app/update', {
        user
    });
    localStorage.setItem('user', JSON.stringify(user));

    let clinic = null;

    if (activeClinic()) {
        clinic = user.clinics?.filter(clinic => clinic.id === activeClinic().id)[0];
    } else {
        clinic = user.clinics ? user.clinics[0] : null;
    }

    localStorage.setItem('activeClinic', JSON.stringify(clinic));
    store.commit('app/update', {
        activeClinic: clinic
    });
}

export function accessToken() : string {
    if (!store.state.app.userAuthToken) {
        throw new Error('User not logged in');
    }

    return store.state.app.userAuthToken;
}

export function activeClinic() : ClinicAttributes {
    return store.state.app.activeClinic;
}

export function switchActiveClinic(clinic: ClinicAttributes) : void {
    store.commit('app/update', {
        activeClinic: clinic
    });

    localStorage.setItem('activeClinic', JSON.stringify(clinic));
}

export function userRoles() : number {
    const roles = store.state.app.activeClinic.roles;

    if (!roles) {
        return 0;
    }

    return roles.filter((role: {user_id: string, roles: number}) => role.user_id === user().id)[0]?.roles ?? 0;
}

const allowedRoleActions : Record<number, string[]> = {
    [UserRoles.Owner]: [
        'dashboard',
        'settings',
        'services'
    ],
    [UserRoles.Admin]: [
        'dashboard',
        'services',
        'patients'
    ],
    [UserRoles.Doctor]: [
        'dashboard',
        'patients'
    ]
};

Vue.prototype.$can = (action: any) => {
    if (!action) {
        return true;
    }

    const userRole = userRoles();

    for (const role in allowedRoleActions) {
        if (!(userRole & (role as unknown as number))) {
            continue;
        }

        if (allowedRoleActions[role].includes(action)) {
            return true;
        }
    }

    return false;
};
