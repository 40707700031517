import { ActionTree } from 'vuex';
import { api } from '@/helpers/api';
import { SettingsState } from '@/store/settings/state';
import { ResourceResponse } from '@/types/responses/ResourceResponse';
import { ClinicAttributes } from '@/types/ClinicAttributes';
import { ValidationResponse } from '@/types/responses/ValidationResponse';
import { FinOperationAttributes } from '@/types/FinOperationAttributes';
import {UserAttributes} from "@/types/UserAttributes";
import {activeClinic} from "@/helpers/auth";

export const actions : ActionTree<SettingsState, any> = {
    async refreshClinic(context) {
        if (!context.state.clinic) {
            return;
        }

        await context.dispatch('fetchClinic', context.state.clinic?.id);
    },

    async fetchFinOperations(context) {
        if (!context.state.clinic) {
            return;
        }

        const result = await api<ResourceResponse<FinOperationAttributes>>({
            endpoint: `clinics/${context.state.clinic.id}/fin-operations`,
            method: 'get'
        });

        context.commit('update', { finOperations: result.data.data });
    },

    async fetchClinic(context, id) : Promise<boolean> {
        context.commit('update', {
            isLoading: true
        });

        const result = await api<ResourceResponse<ClinicAttributes>>({
            endpoint: `clinics/${id}`,
            method: 'get'
        });

        if (result.status !== 200) {
            return false;
        }

        context.commit('update', {
            isLoading: false,
            clinic: result.data.data
        });

        context.commit('loadClinicAsForm', result.data.data);

        return true;
    },

    async inviteUser(context) : Promise<boolean> {
        const result = await api<ValidationResponse>({
            endpoint: 'invites',
            method: 'post',
            data: {
                roles: context.state.inviteForm.roles.reduce((acc, curr) => acc | curr),
                phone: context.state.inviteForm.phone,
                clinic_id: context.state.clinic?.id
            }
        });

        if (result.status === 422) {
            context.commit('inviteForm', {
                errors: result.data.errors
            });

            return false;
        }

        return true;
    },

    async saveClinic(context) : Promise<boolean> {
        context.commit('update', {
            isLoading: true
        });

        const result = await api<ValidationResponse>({
            endpoint: `clinics/${context.state.clinic?.id}`,
            method: 'patch',
            data: {
                name: context.state.form.name,
                appointmentConfig: {
                    lengthMinutes: context.state.form.appointmentLengthMinutes,
                    byDays: context.state.form.appointmentByDays
                },
                location: context.state.form.location,
                smsBitmask: context.state.form.smsBitmask,
                phone: context.state.form.clinicPhone,
                allowSelfRegistration: context.state.form.allowSelfRegistration,
                licenseId: context.state.form.licenseId,
            }
        });

        context.commit('update', {
            isLoading: false
        });

        context.commit('form', { errors: {} });

        if (result.status === 422) {
            context.commit('form', { errors: result.data.errors });
        }

        return true;
    },

    async deleteUser(context, user: UserAttributes) {
        const result = await api({
            endpoint: `clinics/${activeClinic().id}/users/${user.id}`,
            method: 'delete'
        });

        if (result.status !== 204) {
            return false;
        }

        await context.dispatch('refreshClinic');

        return true;
    }
};
