import { AppState } from '@/store/app/state';
import { MutationTree } from 'vuex';
import { ServicesState } from '@/store/services/state';

const mutations : MutationTree<ServicesState> = {
    form(state, data: object) {
        Object.assign(state.form, data);
    },

    resetForm(state) {
        state.form = {
            id: '',
            name: '',
            priceUah: '',

            errors: {}
        };
    },

    update(state, data: Partial<AppState>) {
        Object.assign(state, data);
    }
};

export default mutations;
