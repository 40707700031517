import { MutationTree } from 'vuex';
import { PatientsState } from '@/store/patients/state';

const mutations : MutationTree<PatientsState> = {
    form(state, data: object) {
        Object.assign(state.form, data);
    },

    resetForm(state) {
        state.form = {
            id: '',
            fullName: '',
            phone: '',
            birthdayAt: '',

            errors: {}
        };
    },

    update(state, data: Partial<PatientsState>) {
        Object.assign(state, data);
    }
};

export default mutations;
