import { PatientAttributes } from '@/types/PatientAttributes';
import { PaginatedDataResponse } from '@/types/responses/PaginatedDataResponse';

export interface PatientsState {
    isLoading: boolean;

    patients: PatientAttributes[];

    patientView: PatientAttributes | null;

    search: string;

    tableOptions: object,

    paginationMeta: PaginatedDataResponse['meta'] | null,

    isCreateEditModalOpened: boolean,

    form: Partial<PatientAttributes> | {
        id: string;
        fullName: string;
        phone: string;
        birthdayAt: string | Date;
        errors: Record<string, string[]>,
    }
}

const state : PatientsState = {
    isLoading: true,

    patients: [],

    patientView: null,

    search: '',

    tableOptions: {},

    paginationMeta: null,

    isCreateEditModalOpened: false,

    form: {
        id: '',
        fullName: '',
        phone: '',
        birthdayAt: '',
        errors: {}
    }
};

export default state;
