import { MutationTree } from 'vuex';
import { AppointmentsState } from '@/store/appointments/state';
import { PublicAppointmentState } from '@/store/public-appointment/state';

const mutations : MutationTree<PublicAppointmentState> = {
    update(state, data: Partial<AppointmentsState>) {
        Object.assign(state, data);
    },

    researchForm(state, data: object) {
        Object.assign(state.researchForm, data);
    },

    resetForm(state) {
        state.researchForm = {
            id: '',
            file: null,
            errors: {}
        };
    }
};

export default mutations;
