import { ActionTree } from 'vuex';
import { api } from '@/helpers/api';
import { PaginatedDataResponse } from '@/types/responses/PaginatedDataResponse';
import { ValidationResponse } from '@/types/responses/ValidationResponse';
import { ServiceAttributes } from '@/types/ServiceAttributes';
import { activeClinic } from '@/helpers/auth';
import { ResourceResponse } from '@/types/responses/ResourceResponse';
import { AppointmentsState } from '@/store/appointments/state';
import AppointmentAttributes from '@/types/AppointmentAttributes';
import { PatientAttributes } from '@/types/PatientAttributes';
import { UserAttributes } from '@/types/UserAttributes';
import SelfRegistrationAttributes from '@/types/SelfRegistrationAttributes';
import { AppointmentStatuses } from '@/types/enums/AppointmentStatuses';

const actions : ActionTree<AppointmentsState, any> = {
    async fetchList(context) {
        context.commit('update', { isLoading: true });

        const result = await api<PaginatedDataResponse<AppointmentAttributes[]>>({
            endpoint: `clinics/${activeClinic().id}/appointments`,
            method: 'get',
            params: {
                from: context.state.searchFromDate,
                to: context.state.searchToDate,
                doctorIds: context.state.searchDoctorIds
            }
        });

        context.commit('update', {
            isLoading: false,
            appointments: result.data.data
        });
    },

    async createPatient(context) {
        context.commit('update', { isLoading: true });

        const result = await api<ResourceResponse<PatientAttributes>>({
            endpoint: 'patients',
            method: 'post',
            data: {
                fullName: context.state.createForm.patientFullName,
                phone: context.state.createForm.patientPhone,
                birthdayAt: context.state.createForm.patientBirthday,
                clinicId: activeClinic().id
            }
        });

        if (result.status !== 201) {
            context.commit('update', { isLoading: false });
            return false;
        }

        context.commit('update', {
            isLoading: false,
            createPatients: [
                ...context.state.createPatients,
                result.data.data
            ]
        });

        context.commit('form', { patientId: result.data.data.id });

        return true;
    },

    async fetchPatients(context) {
        context.commit('update', { isLoading: true });

        const result = await api<ResourceResponse<PatientAttributes[]>>({
            endpoint: `clinics/${activeClinic().id}/patients-by-phone/${context.state.createForm.patientPhone}`
        });

        if (result.status !== 200) {
            return;
        }

        context.commit('update', {
            isLoading: false,
            createPatients: result.data.data
        });
    },

    async fetchDoctors(context) {
        context.commit('update', { isLoading: true });

        const result = await api<ResourceResponse<UserAttributes[]>>({
            endpoint: `clinics/${activeClinic().id}/doctors`
        });

        context.commit('update', {
            isLoading: false,
            doctors: result.data.data
        });
    },

    async createAppointment(context) : Promise<boolean> {
        const result = await api<ValidationResponse & ResourceResponse<ServiceAttributes>>({
            endpoint: 'appointments',
            method: 'post',
            data: {
                startsAt: context.state.createForm.startsAt,
                endsAt: context.state.createForm.endsAt,
                status: 'waiting',
                appointmentType: context.state.createForm.appointmentType,
                patientId: context.state.createForm.patientId,
                doctorId: context.state.createForm.doctorId,
                clinicId: activeClinic().id,
                selfRegistrationId: context.state.createFromSelfRegistration?.id ?? null
            }
        });

        if (result.status !== 201) {
            context.commit('form', { errors: result.data.errors });
            return false;
        }

        return true;
    },

    async fromSelfRegistration(context, selfRegistrationId: string) {
        const result = await api<ResourceResponse<SelfRegistrationAttributes>>({
            endpoint: `self-registrations/${selfRegistrationId}`,
            method: 'get'
        });

        if (result.status !== 200) {
            return;
        }

        const item = result.data.data;

        context.commit('update', {
            createFromSelfRegistration: item,
            createForm: {
                ...context.state.createForm,

                appointmentType: item.appointmentType,
                patientPhone: item.patientPhone,
                patientFullName: '',
                doctorId: item.doctor?.id,
                startsAt: item.startsAt
            }
        });
    },

    async cancelAppointment(context, appointment: AppointmentAttributes) {
        const result = await api({
            endpoint: `appointments/${appointment.id}`,
            method: 'patch',
            data: {
                clinicId: appointment.clinicId,
                status: AppointmentStatuses.Canceled
            }
        });

        await context.dispatch('fetchList');

        return result.status === 200;
    }
};

export default actions;
