import AppointmentAttributes from '@/types/AppointmentAttributes';
import { PatientAttributes } from '@/types/PatientAttributes';
import { ServiceAttributes } from '@/types/ServiceAttributes';

export interface AppointmentViewState {
    isLoading: boolean;

    originAppointment: AppointmentAttributes | null;
    appointment: AppointmentAttributes | null;

    patient: PatientAttributes | null;
    services: ServiceAttributes[];

    attachmentForm: {
        description: string,
        attachmentAt: string | Date,
        file: File | null,

        errors: Record<string, string[]>,
    },

    researchForm: {
        researchType: string,
        description: string,

        errors: Record<string, string[]>,
    }
}

const state : AppointmentViewState = {
    isLoading: true,
    originAppointment: null,
    appointment: null,
    patient: null,
    services: [],

    attachmentForm: {
        description: '',
        attachmentAt: '',
        file: null,
        errors: {}
    },

    researchForm: {
        researchType: '',
        description: '',
        errors: {}
    }
};

export default state;
