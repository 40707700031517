import { ActionTree } from 'vuex';
import { PatientsState } from '@/store/patients/state';
import { PatientAttributes } from '@/types/PatientAttributes';
import { api, prepareRequestParams } from '@/helpers/api';
import { PaginatedDataResponse } from '@/types/responses/PaginatedDataResponse';
import { ValidationResponse } from '@/types/responses/ValidationResponse';
import { activeClinic } from '@/helpers/auth';
import { ResourceResponse } from '@/types/responses/ResourceResponse';

const actions : ActionTree<PatientsState, any> = {
    async fetchList(context) {
        context.commit('update', { isLoading: true });

        const result = await api<PaginatedDataResponse<PatientAttributes[]>>({
            endpoint: `clinics/${activeClinic().id}/patients`,
            method: 'get',
            params: {
                ...prepareRequestParams(context.state.tableOptions),
                search: context.state.search
            }
        });

        context.commit('update', {
            isLoading: false,
            patients: result.data.data,
            paginationMeta: result.data.meta
        });
    },

    async savePatient(context) : Promise<boolean> {
        const result = await api<ValidationResponse & ResourceResponse<PatientAttributes>>({
            endpoint: context.state.form.id ? `patients/${context.state.form.id}` : 'patients',
            method: context.state.form.id ? 'put' : 'post',
            data: {
                ...context.state.form,
                clinicId: activeClinic().id
            }
        });

        if (result.status !== 201) {
            context.commit('form', { errors: result.data.errors });
            return false;
        }

        return true;
    },

    async fetchPatientView(context, patientId : string) : Promise<boolean> {
        context.commit('update', { isLoading: true });
        const result = await api<ResourceResponse<PatientAttributes>>({
            endpoint: `patients/${patientId}`,
            method: 'get'
        });

        if (result.status !== 200) {
            return false;
        }

        context.commit('update', {
            patientView: result.data.data,
            isLoading: false
        });
        return true;
    }
};

export default actions;
