import { MutationTree } from 'vuex';
import { AppointmentsState } from '@/store/appointments/state';

const mutations : MutationTree<AppointmentsState> = {
    form(state, data: object) {
        Object.assign(state.createForm, data);
    },

    resetForm(state) {
        state.createForm = {
            appointmentType: 'regular',
            patientPhone: '+380',
            patientFullName: '',
            patientBirthday: '',
            doctorId: '',
            startsAt: '',
            endsAt: '',
            patientId: '',

            errors: {}
        };
    },

    update(state, data: Partial<AppointmentsState>) {
        Object.assign(state, data);
    }
};

export default mutations;
