import { MutationTree } from 'vuex';
import { ClinicsCreateStore } from '@/store/clinics-create/state';

const mutations : MutationTree<ClinicsCreateStore> = {
    form(state, data: object) {
        Object.assign(state.form, data);
    },

    resetForm(state) {
        state.form = {
            name: '',
            location: '',
            smsBitmask: 0,

            errors: {}
        };
    }
};

export default mutations;
