import { RegisterState } from '@/store/register/state';
import { ActionTree } from 'vuex';
import { api, prepareRequestData } from '@/helpers/api';
import { ValidationResponse } from '@/types/responses/ValidationResponse';
import { AuthResponse } from '@/types/responses/AuthResponse';

export const actions : ActionTree<RegisterState, any> = {
    async register(context) : Promise<false | AuthResponse> {
        const result = await api<ValidationResponse & AuthResponse>({
            endpoint: 'register',
            method: 'post',
            data: prepareRequestData(context.state.form)
        });

        if (result.status !== 200) {
            context.commit('form', { errors: result.data.errors });
            return false;
        }

        return result.data;
    }
};
