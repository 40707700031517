import { UserAttributes } from '@/types/UserAttributes';
import { ClinicAttributes } from '@/types/ClinicAttributes';
import { NotificationAttributes } from '@/types/NotificationAttributes';

export interface AppState {
    alert: {text: string, color: string} | false,

    shallContentShowOverlay: boolean,

    user?: UserAttributes;

    userAuthToken?: string;

    activeClinic?: ClinicAttributes;

    notifications: NotificationAttributes[];
}

const state : AppState = {
    alert: false,

    shallContentShowOverlay: false,

    notifications: []
};

export default state;
