import { ServiceAttributes } from '@/types/ServiceAttributes';
import { PaginatedDataResponse } from '@/types/responses/PaginatedDataResponse';

export interface ServicesState {
    isLoading: boolean,

    services: ServiceAttributes[],

    search: string;

    tableOptions: object,

    paginationMeta: PaginatedDataResponse['meta'] | null,

    isCreateEditModalOpened: false,

    form: {
        id: string,
        name: string,
        priceUah: string | number,
        errors: Record<string, string[]>
    }
}

const state : ServicesState = {
    isLoading: true,

    services: [],

    search: '',

    tableOptions: {},

    paginationMeta: null,

    isCreateEditModalOpened: false,

    form: {
        id: '',
        name: '',
        priceUah: '',
        errors: {}
    }
};

export default state;
