import { ActionTree } from 'vuex';
import { AppState } from '@/store/app/state';
import { api } from '@/helpers/api';
import { ResourceResponse } from '@/types/responses/ResourceResponse';
import { NotificationAttributes } from '@/types/NotificationAttributes';

const alertTimeoutSeconds = 10;

export const actions : ActionTree<AppState, any> = {
    showAlert(context, alert: {text: string, color: string}) {
        context.commit('update', {
            alert
        });

        setTimeout(() => {
            context.commit('update', {
                alert: false
            });
        }, alertTimeoutSeconds * 1000);
    },

    async fetchNotifications(context) {
        const result = await api<ResourceResponse<NotificationAttributes[]>>({
            endpoint: 'notifications'
        });

        if (result.status !== 200) {
            return;
        }

        context.commit('update', {
            notifications: result.data.data
        });
    },

    async markNotificationsRead(context) {
        const result = await api({
            endpoint: 'notifications/mark-read',
            method: 'post'
        });

        if (result.status !== 204) {
            return;
        }

        context.dispatch('fetchNotifications');
    }
};
