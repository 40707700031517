export enum AppointmentStatuses {
    WaitingApproval = 'waiting-approval',
    Waiting = 'waiting',
    Started = 'started',
    Ended = 'ended',
    Paid = 'paid',
    Canceled = 'canceled'
}

export const appointmentStatuses = {
    [AppointmentStatuses.WaitingApproval]: {
        label: 'Очікує підтвердження',
        color: 'warning'
    },

    [AppointmentStatuses.Waiting]: {
        label: 'Заплановано',
        color: 'warning'
    },

    [AppointmentStatuses.Started]: {
        label: 'Проводится',
        color: 'success'
    },

    [AppointmentStatuses.Ended]: {
        label: 'Завершено',
        color: 'info'
    },

    [AppointmentStatuses.Paid]: {
        label: 'Сплачено',
        color: 'success'
    },

    [AppointmentStatuses.Canceled]: {
        label: 'Відмінено',
        color: 'secondary'
    }
};
