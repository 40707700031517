import { ActionTree } from 'vuex';
import { ServicesState } from '@/store/services/state';
import { api, prepareRequestParams } from '@/helpers/api';
import { PaginatedDataResponse } from '@/types/responses/PaginatedDataResponse';
import { ServiceAttributes } from '@/types/ServiceAttributes';
import { activeClinic } from '@/helpers/auth';
import { ValidationResponse } from '@/types/responses/ValidationResponse';
import { ResourceResponse } from '@/types/responses/ResourceResponse';

export const actions : ActionTree<ServicesState, any> = {
    async fetchList(context) {
        context.commit('update', { isLoading: true });

        const result = await api<PaginatedDataResponse<ServiceAttributes[]>>({
            endpoint: `clinics/${activeClinic().id}/services`,
            method: 'get',
            params: {
                ...prepareRequestParams(context.state.tableOptions),
                search: context.state.search
            }
        });

        context.commit('update', {
            isLoading: false,
            services: result.data.data,
            paginationMeta: result.data.meta
        });
    },

    async saveService(context) : Promise<boolean> {
        const result = await api<ValidationResponse & ResourceResponse<ServiceAttributes>>({
            endpoint: context.state.form.id ? `services/${context.state.form.id}` : 'services',
            method: context.state.form.id ? 'put' : 'post',
            data: {
                ...context.state.form,
                clinicId: activeClinic().id
            }
        });

        if (result.status !== 201) {
            context.commit('form', { errors: result.data.errors });
            return false;
        }

        return true;
    }
};
