export interface LoginState {
    form: {
        phone: string,
        password: string,

        errors: Record<string, string[]>
    }
}

const state : LoginState = {
    form: {
        phone: '+380',
        password: '',

        errors: {}
    }
};

export default state;
