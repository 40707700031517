export interface ClinicsCreateStore {
    form: {
        name: string,
        location: string,
        smsBitmask: number,

        errors: Record<string, string[]>,
    },

}

const state : ClinicsCreateStore = {
    form: {
        name: '',
        location: '',
        smsBitmask: 0,

        errors: {}
    }
};

export default state;
