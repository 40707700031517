export enum UserRoles {
    Owner = 1 << 0,
    Admin = 1 << 1,
    Doctor = 1 << 2
}

export const userRolesLabels : Record<number, string> = {
    [UserRoles.Owner]: 'Власник',
    [UserRoles.Admin]: 'Адміністратор',
    [UserRoles.Doctor]: 'Лікар'
};
