import state, { PatientsState } from './state';
import mutations from './mutations';
import actions from './actions';
import { Module } from 'vuex';

const store : Module<PatientsState, any> = {
    namespaced: true,
    state: state,
    getters: {},
    mutations: mutations,
    actions: actions
};

export default store;
