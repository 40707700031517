import { ActionTree } from 'vuex';
import { api } from '@/helpers/api';
import { ResourceResponse } from '@/types/responses/ResourceResponse';
import { PublicSelfRegistrationState } from '@/store/public-self-registration/state';
import { ClinicAttributes } from '@/types/ClinicAttributes';
import { UserAttributes } from '@/types/UserAttributes';
import { ValidationResponse } from '@/types/responses/ValidationResponse';

const actions : ActionTree<PublicSelfRegistrationState, any> = {
    async fetchClinic(context, id) {
        context.commit('update', { isLoading: true });

        const result = await api<ResourceResponse<Partial<ClinicAttributes>>>({
            endpoint: `public/clinics/${id}`,
            method: 'get'
        });

        context.commit('update', {
            isLoading: false
        });

        if (result.status !== 200) {
            return false;
        }

        context.commit('update', {
            clinic: result.data.data
        });

        context.dispatch('fetchDoctors', id);

        return true;
    },

    async fetchDoctors(context, id) {
        context.commit('update', { isLoading: true });

        const result = await api<ResourceResponse<Partial<UserAttributes>[]>>({
            endpoint: `public/clinics/${id}/doctors`,
            method: 'get'
        });

        context.commit('update', {
            isLoading: false
        });

        if (result.status !== 200) {
            return false;
        }

        context.commit('update', {
            doctors: result.data.data
        });

        return true;
    },

    async create(context) {
        context.commit('update', { isLoading: true });

        const result = await api<ResourceResponse<Partial<UserAttributes>[]> & ValidationResponse>({
            endpoint: 'public/self-registrations',
            method: 'post',
            data: {
                ...context.state.form,
                startsAt: context.state.form.startsAt,
                clinicId: context.state.clinic?.id
            }
        });

        context.commit('update', {
            isLoading: false
        });

        if (result.status === 422) {
            context.commit('form', { errors: result.data.errors });
            return false;
        }

        context.commit('resetForm');

        return true;
    }
};

export default actions;
