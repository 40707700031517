import { PatientAttributes } from '@/types/PatientAttributes';
import AppointmentAttributes from '@/types/AppointmentAttributes';
import { UserAttributes } from '@/types/UserAttributes';
import SelfRegistrationAttributes from '@/types/SelfRegistrationAttributes';

export interface AppointmentsState {
    isLoading: boolean;

    appointments: AppointmentAttributes[];
    searchFromDate: string | null;
    searchToDate: string | null;
    searchDoctorIds: string[];

    // Create screen
    createPatients: PatientAttributes[];
    doctors: UserAttributes[];
    createFromSelfRegistration: SelfRegistrationAttributes | null;
    createForm: {
        appointmentType: 'regular' | 'online';
        patientPhone: string;
        patientFullName: string;
        patientBirthday: string | Date;
        doctorId: string;
        startsAt: string | Date;
        endsAt: string | Date;
        patientId: string,

        errors: Record<string, string[]>,
    }
}

const state : AppointmentsState = {
    isLoading: true,

    appointments: [],
    searchFromDate: null,
    searchToDate: null,
    searchDoctorIds: [],

    // Create screen
    createPatients: [],
    doctors: [],
    createFromSelfRegistration: null,
    createForm: {
        appointmentType: 'regular',
        patientPhone: '+380',
        patientFullName: '',
        patientBirthday: '',
        doctorId: '',
        startsAt: '',
        endsAt: '',
        patientId: '',

        errors: {}
    }
};

export default state;
