import axios, { AxiosResponse, Method } from 'axios';
import { accessToken, isAuthenticated, logout } from '@/helpers/auth';
import router from '@/router';
import { config } from '@/config';

const baseApiUrl = config.baseApiUrl;

/**
 * Formats data keys to underscore
 * @param data
 */
export function prepareRequestData(data : Record<string, any>) {
    const result : Record<string, any> = {};

    for (const key in data) {
        const preparedKey = key.split(/(?=[A-Z])/).join('_').toLowerCase();
        result[preparedKey] = data[key];
    }

    return result;
}

/**
 * Convert boolean values to number representation
 * @param params
 */
export function prepareRequestParams(params: Record<string, any>) {
    const processValue = (value: any) : any => {
        if (typeof value === 'boolean') {
            return value ? 1 : 0;
        }

        if (Array.isArray(value)) {
            return value.map(v => processValue(v));
        }

        return value;
    };

    return Object.fromEntries(
        Object.entries(params).map(([key, value]) => {
            return [key, processValue(value)];
        })
    );
}

export async function api<R = any>(
    request: {
        method?: Method,
        endpoint: string,
        data?: Record<string, any>,
        params?: Record<string, any>,
        headers?: Record<string, any>
    }
) : Promise<AxiosResponse<R>> {
    request.endpoint = baseApiUrl + request.endpoint;

    const headers : Record<string, string> = {
        Accept: 'application/json',
        ...request.headers ?? {}
    };

    if (isAuthenticated()) {
        headers.Authorization = 'Bearer ' + accessToken();
    }

    const response = await axios.request<R>({
        method: request.method || 'get',
        headers,
        data: request.data,
        params: request.params,
        url: request.endpoint,
        validateStatus: (status) => status < 500
    });

    if (response.status === 403) {
        logout();
        await router.push({ name: 'login' });
    }

    return response;
}
