import moment from 'moment';
const humanizeDuration = require('humanize-duration');

export function formatDate(date: string | Date) : string {
    return moment(date).locale('uk').format('D MMMM YYYY');
}

export function formatDateTime(date: string | Date) : string {
    return moment(date).locale('uk').format('D MMMM YYYY, HH:mm');
}

export function formatDateDiff(date: string | Date) : string {
    const diffSeconds = moment().diff(date, 'seconds');

    return humanizeDuration(
        diffSeconds * 1000,
        { language: 'uk', largest: 1 }
    ) + ' тому';
}

export function formatFutureDiff(date: string | Date, text: string = '%diff%') : string {
    if (moment().isAfter(date)) {
        return 'Прострочено';
    }

    const diffSeconds = moment().diff(date, 'seconds');

    const diff = humanizeDuration(
        diffSeconds * 1000,
        { language: 'uk', largest: 1 }
    );

    return text.replace('%diff%', diff);
}
