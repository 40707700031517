<template>
    <v-menu
        offset-y
        left
        nudge-bottom="14"
        min-width="230"
        content-class="user-profile-menu-content"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-badge
                bottom
                color="success"
                overlap
                offset-x="12"
                offset-y="12"
                class="ms-4"
                dot
            >
                <v-avatar
                    size="40px"
                    v-bind="attrs"
                    color="primary"
                    class="v-avatar-light-bg primary--text"
                    v-on="on"
                >
                    <v-img
                        v-if="userData.avatar"
                        :src="userData.avatar"
                    ></v-img>
                    <v-icon
                        v-else
                        color="primary"
                        size="28"
                    >
                        {{ icons.mdiAccountOutline }}
                    </v-icon>
                </v-avatar>
            </v-badge>
        </template>
        <v-list>
            <div class="pb-3 pt-2">
                <v-badge
                    bottom
                    color="success"
                    overlap
                    offset-x="12"
                    offset-y="12"
                    class="ms-4"
                    dot
                >
                    <v-avatar
                        size="40px"
                        color="primary"
                        class="v-avatar-light-bg primary--text"
                    >
                        <v-img
                            v-if="userData.avatar"
                            src="@/assets/images/avatars/1.png"
                        ></v-img>
                        <v-icon
                            v-else
                            color="primary"
                            size="28"
                        >
                            {{ icons.mdiAccountOutline }}
                        </v-icon>
                    </v-avatar>
                </v-badge>
                <div
                    class="d-inline-flex flex-column justify-center ms-3"
                    style="vertical-align:middle"
                >
                    <span class="text--primary font-weight-semibold mb-n1">
                        {{ userData.fullName || userData.username }}
                    </span>
                    <small class="text--disabled text-capitalize">{{ userData.role }}</small>
                </div>
            </div>

            <v-divider></v-divider>

            <!-- Profile -->
            <v-list-item :to="{ name: 'profile' }">
                <v-list-item-icon class="me-2">
                    <v-icon size="22">
                        {{ icons.mdiAccountOutline }}
                    </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Мій профіль</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item v-for="clinic in userClinics" @click="switchClinic(clinic)">
                <v-list-item-icon class="me-2">
                    <v-icon size="22">
                        {{ icons.mdiLogin }}
                    </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>
                        {{ clinic.name }}
                        <span v-if="activeUserClinic && clinic.id === activeUserClinic.id">(активна)</span>
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item :to="{name: 'clinics-create'}">
                <v-list-item-icon class="me-2">
                    <v-icon size="22">
                        {{ icons.mdiPlus }}
                    </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>
                        Створити нову клініку
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-divider class="my-2"></v-divider>

            <v-list-item @click="logoutUser">
                <v-list-item-icon class="me-2">
                    <v-icon size="22">
                        {{ icons.mdiLogoutVariant }}
                    </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Вийти</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
import {
    mdiAccountOutline,
    mdiChatOutline,
    mdiCheckboxMarkedOutline,
    mdiCogOutline,
    mdiCurrencyUsd,
    mdiEmailOutline,
    mdiHelpCircleOutline,
    mdiLogin,
    mdiLogoutVariant, mdiPlus
} from '@mdi/js';
import {useRouter} from '@core/utils';
import {computed, getCurrentInstance} from '@vue/composition-api';
import store from "@/store";
import {activeClinic, logout, switchActiveClinic} from "@/helpers/auth";

export default {
    setup() {
        const vm = getCurrentInstance().proxy;
        const { router } = useRouter();
        const userData = computed(() => store.state.app.user);

        const switchClinic = (clinic) => {
            switchActiveClinic(clinic);
            window.location.reload();
        };

        const logoutUser = () => {
            logout();

            // Redirect to login page
            router.push({ name: 'login' });
        };

        const userClinics = computed(() => store.state.app.user?.clinics);
        const activeUserClinic = computed(() => activeClinic());

        return {
            logoutUser,
            userData,
            userClinics,
            activeUserClinic,
            switchClinic,

            icons: {
                mdiAccountOutline,
                mdiEmailOutline,
                mdiCheckboxMarkedOutline,
                mdiChatOutline,
                mdiCogOutline,
                mdiCurrencyUsd,
                mdiHelpCircleOutline,
                mdiLogoutVariant,
                mdiLogin,
                mdiPlus
            }
        };
    }
};
</script>

<style lang="scss">
.user-profile-menu-content {
    .v-list-item {
        min-height: 2.5rem !important;
    }
}
</style>
