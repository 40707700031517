import { ActionTree } from 'vuex';
import { api, prepareRequestData } from '@/helpers/api';
import store from '@/store';
import { LoginState } from '@/store/login/state';
import { ValidationResponse } from '@/types/responses/ValidationResponse';
import { AuthResponse } from '@/types/responses/AuthResponse';

export const actions : ActionTree<LoginState, any> = {
    async login(context) : Promise<false | AuthResponse> {
        const result = await api<ValidationResponse & AuthResponse>({
            endpoint: 'login',
            method: 'post',
            data: prepareRequestData(store.state.login.form)
        });

        if (result.status === 422) {
            context.commit('form', { errors: result.data.errors });
            return false;
        }

        return result.data;
    }
};
