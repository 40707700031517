import dashboard from '@/store/dashboard';
import Vue from 'vue';
import Vuex, { createLogger, Module } from 'vuex';
import app from './app';
import patients from '@/store/patients';
import login from '@/store/login';
import register from '@/store/register';
import { loadUser } from '@/helpers/auth';
import clinicsCreate from '@/store/clinics-create';
import services from '@/store/services';
import appointments from '@/store/appointments';
import appointmentView from '@/store/appointment-view';
import publicAppointment from '@/store/public-appointment';
import settings from '@/store/settings';
import profile from '@/store/profile';
import publicSelfRegistration from '@/store/public-self-registration';
import selfRegistrations from '@/store/self-registrations';

const appConfigStoreModule = require('../@core/@app-config/appConfigStoreModule.js');

Vue.use(Vuex);

export default new Vuex.Store({
    plugins: [createLogger()],
    strict: true,
    modules: {
        appConfig: appConfigStoreModule.default as unknown as Module<object, any>,
        app,
        login,
        register,
        dashboard,
        patients,
        clinicsCreate,
        services,
        appointments,
        appointmentView,
        publicAppointment,
        settings,
        profile,
        publicSelfRegistration,
        selfRegistrations
    }
});

loadUser();
