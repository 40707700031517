import { MutationTree } from 'vuex';
import { LoginState } from '@/store/login/state';

const mutations : MutationTree<LoginState> = {
    form(state, data: object) {
        Object.assign(state.form, data);
    },

    resetForm(state) {
        state.form = {
            phone: '+380',
            password: '',

            errors: {}
        };
    }
};

export default mutations;
